* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  background: black;
  color: white;
  user-select: none;
  font-family: 'Roboto', 'Trebuchet MS', sans-serif;
}

.home {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.main {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.rightHeader {
  position: absolute;
  top: 1vh;
  right: 3vw;
  font-size: 2.5em;
  font-weight: 200;
  font-variant-numeric: tabular-nums;
  letter-spacing: -2px;
}

.footer {
  position: absolute;
  bottom: 3vh;
  right: 3vw;
  font-size: 2.5em;
  font-weight: 200;
  font-variant-numeric: tabular-nums;
  letter-spacing: -2px;
}
